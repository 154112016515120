<template>
  <v-container>
    <template v-if="showWeightPromt()">
      <v-card v-if="loaded">
        <WeightPrompt @done="done" :show="showWeightPromt()" class="pt-10" />
      </v-card>
    </template>
    <template v-else>
      <template v-if="loaded">
        <BaseScreenHeader
          title="Record Workout"
          screenInfo="WRK-013"
          :showBack="false"
          :refresh="true"
          @refresh="startUp()"
        />
        <BaseLoading :loaded="loaded" />
        <v-card
          class="charcoal silver--text mt-3"
          :style="charcoalTileMenu"
          tile
          flat
        >
          <v-card-actions class="pa-0 mb-2">
            <BaseActionButton
              color="orange"
              class=""
              text
              small
              icon="mdi-close"
              label="DELETE"
              @clickedThis="deleteWorkout"
            />
            <BaseActionButton
              color="pinkAccent"
              class=""
              text
              small
              icon="mdi-reload-alert"
              label="RESET"
              @clickedThis="resetWorkout"
            />
            <v-spacer />
            <BaseActionButton
              text
              label="Finalize"
              icon="mdi-arrow-down-box"
              @clickedThis="startFinalize"
              color="paper"
            />
          </v-card-actions>
          <v-card-actions v-if="instance" :class="textSizeSmall + ' pa-0'">
            <v-icon color="silver" class="mr-1" large>
              mdi-clock-plus-outline
            </v-icon>
            Started {{ ago }}
          </v-card-actions>
          <template v-if="instance && enableRestTimers">
            <v-card-actions :class="textSizeSmall + ' pa-0 mt-1 silver--text'">
              <v-icon color="silver" class="mr-1" large>
                mdi-run-fast
              </v-icon>
              <span class="silver--text">
                {{ activeMinutes > 0 ? activeMinutes : 0 }} active minutes
              </span>
            </v-card-actions>
            <v-card-actions :class="textSizeSmall + ' pa-0 silver--text'">
              <v-icon color="silver" class="mr-1" large>
                mdi-fire
              </v-icon>

              <span class="silver--text">
                Burned {{ calories > 0 ? calories : 0 }} kCal
              </span>
            </v-card-actions>
          </template>

          <v-card-actions class="py-0 px-0">
            <v-switch
              dark
              color="progressActive"
              v-model="metric"
              class="px-2 mb-n2"
            >
              <template v-slot:label>
                <span class="silver--text">Metric?</span>
              </template>
            </v-switch>
            <v-spacer />
            <v-switch
              dark
              color="progressActive"
              v-model="enableRestTimers"
              class="px-2 mb-n2"
              @change="saveRestTimerChoice()"
            >
              <template v-slot:label>
                <span class="silver--text">
                  {{ enableRestTimers ? 'Rest Timers On' : 'Rest Timers Off' }}
                </span>
              </template>
            </v-switch>
          </v-card-actions>
        </v-card>
        <v-card
          rounded
          class="px-0 mt-4 d-flex flex-column charcoal "
          elevation="1"
        >
          <v-sheet class="transparent paper--text pb-2 charcoalTile">
            <v-card-title :class="textSize + ' px-2'">
              {{ workout.name }} <v-spacer />
              <v-icon color="silver">
                {{ iconNumber(workout.rounds - 1) }}
              </v-icon>
            </v-card-title>
            <v-card-subtitle
              :class="textSizeXSmall + ' silver--text px-2 pb-2 pt-0 mb-2'"
            >
              {{ workout.description }}
            </v-card-subtitle>
            <v-card-actions :class="textSizeSmall + ' silver--text px-2 pb-0 '">
              <span v-if="effort > 0">
                Avg. Effort
              </span>
              <v-spacer />
              Total Volume
            </v-card-actions>
            <v-card-actions
              :class="textSizeSmall + ' progressActive--text px-2 py-0'"
            >
              <span v-if="effort > 0"> {{ effort }}% </span>
              <v-spacer />
              {{ totalImperial.toLocaleString() }} lbs /
              {{ totalMetric.toLocaleString() }} Kg
            </v-card-actions>
          </v-sheet>
          <v-card-actions :class="textSize + '  silver--text px-1 pb-0 mt-5'">
            Exercises
            <v-spacer />
          </v-card-actions>
          <v-expansion-panels
            v-if="loaded"
            accordion
            focusable
            :key="switchNum"
            flat
            tile
            class="charcoal darken-1 mt-0"
          >
            <v-expansion-panel
              class="charcoal darken-1 "
              v-for="(exercise, index) in exercises"
              :key="index"
            >
              <v-expansion-panel-header
                class="pl-2 pr-0 py-5 charcoal rounded paper--text charcoalTile"
                ripple
                dark
                tile
              >
                <v-card flat tile class="transparent">
                  <v-card-actions
                    :class="
                      textSizeSmall + ' transparent paper--text pl-0 pt-0'
                    "
                  >
                    <v-icon
                      :color="
                        exercise.saved == 'Yes' ? 'progressActive' : 'paper'
                      "
                    >
                      {{ iconNumber(exercise.sort - 1) }}
                    </v-icon>
                    <div class="ml-1">
                      <span :class="textSizeSmall">
                        {{ exercise.name }}
                      </span>
                    </div>
                  </v-card-actions>
                </v-card>
                <template v-slot:actions>
                  <v-icon x-large color="paper">$expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-sheet tile class="charcoal charcoalTile mb-2">
                <v-card-actions v-if="!packed" class="charcoal px-0 mt-0 pb-0">
                  <BaseActionButton
                    color="paper"
                    class="ml-n1"
                    plain
                    label="Instructions"
                    icon=""
                    @clickedThis="showExerciseInfoDialog(exercise.id)"
                  />
                  <v-spacer />
                </v-card-actions>
              </v-sheet>
              <v-expansion-panel-content class="charcoal mt-n2 px-0 ">
                <v-card-actions
                  :style="charcoalTileLight"
                  class="ml-n1 pa-0 paper"
                >
                  <v-spacer />
                  <v-img
                    :max-height="120"
                    :max-width="120"
                    :min-height="120"
                    :src="exercise.image"
                    :alt="exercise.name"
                    :title="exercise.name"
                    contain
                    @click.capture="showExerciseInfoDialog(exercise.id)"
                    class="elevation-0"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="accent lighten-4"
                        />
                      </v-row>
                    </template>
                  </v-img>
                  <v-spacer />
                </v-card-actions>
                <template
                  v-if="
                    exercise.track_time == 'No' &&
                      exercise.track_distance == 'No'
                  "
                >
                  <v-sheet v-if="exercise.repscheme" class="ml-1 charcoal link">
                    <v-card-text :class="textSize + ' paper--text pa-0'">
                      {{ exercise.repscheme.name }}
                    </v-card-text>
                    <v-card-text
                      :class="textSizeXSmall + ' silver--text px-0 mt-n2 pt-0'"
                    >
                      {{ exercise.sets }} SETS ({{ exercise.repscheme.scheme }})
                    </v-card-text>
                  </v-sheet>
                  <v-card-actions class="py-1 pl-1 silver--text">
                    <template v-if="exercise.effort > 0">
                      <span :class="textSizeXSmall + ' mr-2'">
                        Effort: {{ oneDigit(exercise.effort) }}%
                      </span>
                      <v-divider />
                    </template>
                    <template v-else>
                      <v-divider />
                    </template>
                    <span :class="textSizeXSmall + ' ml-2'">
                      Exercise Vol.
                      {{ exercise.total_imperial.toLocaleString() }} lbs /
                      {{ exercise.total_metric.toLocaleString() }} kg
                    </span>
                  </v-card-actions>
                  <v-sheet rounded class="transparent">
                    <v-btn
                      block
                      height="65"
                      class="mb-5 progressActive--text transparent charcoalTileMenu"
                      @click="changeDefault(exercise)"
                    >
                      <span class="text-h6">Set Reps & Weight</span>

                      <v-icon large color="" title="Set Reps & Weight">
                        mdi-gesture-tap
                      </v-icon>
                    </v-btn>
                  </v-sheet>
                </template>
                <v-row
                  dense
                  v-for="(set, index) in workoutSets.filter(function(e) {
                    return e.exerciseid == exercise.id
                  })"
                  :key="index"
                >
                  <v-col cols="11" class="pa-0 ma-0">
                    <v-card-actions class="px-0 py-0 mr-n2">
                      <v-icon :color="set.color" round @click="startSet(set)">
                        {{ iconNumber(set.setnum - 1) }}
                      </v-icon>
                      <template v-if="set.effort > 0">
                        <span
                          :class="
                            textSizeXSmall + ' silver--text pl-1 pb-0  link'
                          "
                          @click="saveOneRM(exercise)"
                        >
                          <span
                            :class="
                              effortColor(exercise, set, false).concat('--text')
                            "
                          >
                            Effort: {{ set.effort }}%
                          </span>
                        </span>
                      </template>
                      <v-spacer />
                      <template
                        v-if="
                          set.calcRM != null &&
                            set.calcRM.oneRMImperial != null &&
                            set.calcRM.oneRMMetric != null
                        "
                      >
                        <span
                          :class="
                            effortColor(exercise, set, false).concat('--text') +
                              ' pr-1 ' +
                              textSizeXSmall
                          "
                        >
                          Set 1RM:
                          {{
                            metric
                              ? set.calcRM.oneRMMetric.toString() + ' kg'
                              : set.calcRM.oneRMImperial.toString() + ' lbs'
                          }}
                        </span>
                        <span @click="saveOneRM(exercise)" class="mr-2 link">
                          <v-icon
                            :large="!isPhone"
                            :color="effortColor(exercise, set, false)"
                          >
                            mdi-content-save-outline
                          </v-icon>
                        </span>
                      </template>
                    </v-card-actions>
                  </v-col>
                  <v-col cols="1"> </v-col>
                  <v-col cols="12">
                    <v-row dense>
                      <template
                        v-if="
                          exercise.resistance == 'Yes' ||
                            (exercise.track_time != 'Yes' &&
                              exercise.track_distance != 'Yes')
                        "
                      >
                        <v-col cols="3">
                          <v-text-field
                            dark
                            :class="
                              isPhone
                                ? 'enlarged-input-small'
                                : 'enlarged-input'
                            "
                            filled
                            color="success"
                            type="number"
                            pattern="[0-9]*"
                            inputmode="decimal"
                            label="Reps"
                            v-model="set.reps"
                            @input="calculateVolume(set, exercise.bilateral)"
                            @focus="$event.target.select()"
                          />
                        </v-col>
                      </template>
                      <template v-if="exercise.track_time == 'Yes'">
                        <v-col cols="3">
                          <v-text-field
                            dark
                            :class="
                              isPhone
                                ? 'enlarged-input-small'
                                : 'enlarged-input'
                            "
                            filled
                            color="success"
                            type="number"
                            pattern="[0-9]*"
                            inputmode="decimal"
                            label="Mins"
                            v-model="set.time"
                            @input="calculateMetric(set, exercise.bilateral)"
                            @focus="$event.target.select()"
                          />
                        </v-col>
                      </template>
                      <v-spacer />
                      <template v-if="exercise.resistance == 'Yes'">
                        <v-col cols="4">
                          <v-text-field
                            :disabled="metric"
                            dark
                            :class="
                              isPhone
                                ? 'enlarged-input-small'
                                : 'enlarged-input'
                            "
                            filled
                            color="success"
                            type="number"
                            pattern="[0-9]*"
                            inputmode="decimal"
                            label="Lbs"
                            v-model="set.imperial"
                            @input="calculateMetric(set, exercise.bilateral)"
                            @focus="$event.target.select()"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :disabled="!metric"
                            dark
                            :class="
                              isPhone
                                ? 'enlarged-input-small'
                                : 'enlarged-input'
                            "
                            filled
                            color="success"
                            type="number"
                            pattern="[0-9]*"
                            inputmode="decimal"
                            label="Kg"
                            v-model="set.metric"
                            @input="calculateImperial(set, exercise.bilateral)"
                            @focus="$event.target.select()"
                          />
                        </v-col>
                      </template>
                      <template v-if="exercise.track_distance == 'Yes'">
                        <v-col cols="4">
                          <v-text-field
                            :disabled="metric"
                            dark
                            :class="
                              isPhone
                                ? 'enlarged-input-small'
                                : 'enlarged-input'
                            "
                            filled
                            color="success"
                            type="number"
                            pattern="[0-9]*"
                            inputmode="decimal"
                            label="Miles"
                            v-model="set.distance_imperial"
                            @input="calculateMetric(set, exercise.bilateral)"
                            @focus="$event.target.select()"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :disabled="!metric"
                            dark
                            :class="
                              isPhone
                                ? 'enlarged-input-small'
                                : 'enlarged-input'
                            "
                            filled
                            color="success"
                            type="number"
                            pattern="[0-9]*"
                            inputmode="decimal"
                            label="Km"
                            v-model="set.distance_metric"
                            @input="calculateImperial(set, exercise.bilateral)"
                            @focus="$event.target.select()"
                          />
                        </v-col>
                      </template>
                      <v-spacer />
                      <v-col align="left" cols="1" class="mt-3">
                        <v-icon
                          :class="isPhone ? 'ml-n1' : 'ml-n2'"
                          large
                          :color="set.color"
                          @click="startSet(set)"
                        >
                          mdi-check-circle
                        </v-icon>
                      </v-col>
                    </v-row>
                    <v-card-actions
                      v-if="
                        exercise.track_time == 'No' &&
                          exercise.track_distance == 'No'
                      "
                      class="pa-0 mt-n6"
                    >
                      <v-divider class="charcoal lighten-1" />
                      <span :class="textSizeXSmall + ' mx-1 silver--text'">
                        Volume:
                        {{ set.volume_imperial.toLocaleString() }} lbs /
                        {{ set.volume_metric.toLocaleString() }} kg
                      </span>
                      <v-spacer />
                    </v-card-actions>
                    <v-card-actions
                      v-if="set.time > 0 && set.distance_metric > 0"
                      class="pa-0 mt-n6"
                    >
                      <v-spacer />
                      <span :class="textSizeXSmall + ' silver--text'">
                        Pace:
                        {{ set.pace_imperial }} minutes per mile or
                        {{ set.pace_metric }} minutes per km
                      </span>
                      <v-spacer />
                    </v-card-actions>
                    <v-card-actions
                      v-if="set.time > 0 && set.distance_metric > 0"
                      class="pa-0"
                    >
                      <v-spacer />
                      <span :class="textSizeXSmall + ' silver--text'">
                        Speed:
                        {{ set.speed_imperial }} mph or
                        {{ set.speed_metric }} kmph
                      </span>
                      <v-spacer />
                    </v-card-actions>
                  </v-col>
                </v-row>
                <template v-if="exercise.resistance == 'Yes'">
                  <v-sheet
                    v-if="
                      exercise.newRM &&
                        exercise.newRM.oneRMImperial &&
                        exercise.newRM.oneRMImperial > 0
                    "
                    rounded
                    :class="
                      textSizeSmall +
                        ' transparent pa-2 ml-n2 mr-n1 charcoalTileLit'
                    "
                  >
                    <v-card-actions class="pa-0 silver--text">
                      <span
                        v-if="getRM(exercise) != null && getRM(exercise) > 0"
                      >
                        Previous 1RM ({{ unit(metric) }})
                      </span>
                      <span
                        class="progressActive--text link"
                        @click="saveOneRM(exercise)"
                        v-else
                      >
                        SAVE YOUR 1RM!
                      </span>
                      <v-spacer />
                      <span
                        :class="textSizeSmall + ' silver--text text-right pr-0'"
                      >
                        Calculated 1RM
                      </span>
                    </v-card-actions>
                    <v-card-actions class="pa-0">
                      <span
                        v-if="getRM(exercise) != null"
                        class=" text-left pl-0"
                      >
                        <span
                          v-if="getRM(exercise) != null && getRM(exercise) > 0"
                          class="silver--text"
                        >
                          {{ getRM(exercise) }}
                        </span>
                      </span>
                      <v-spacer />
                      <span class="text-right pr-0 pt-1 pl-0 silver--text">
                        <template v-if="exercise.newRM">
                          <BaseActionButton
                            large
                            text
                            class="px-1 charcoalTileMenu"
                            :color="rmColor(exercise, null)"
                            :label="
                              metric
                                ? exercise.newRM.oneRMMetric.toString() +
                                  ' ' +
                                  unit(metric)
                                : exercise.newRM.oneRMImperial.toString() +
                                  ' ' +
                                  unit(metric)
                            "
                            icon="mdi-content-save-check-outline"
                            :title="'Save New 1RM For: ' + exercise.name"
                            @clickedThis="saveOneRM(exercise)"
                          />
                        </template>
                      </span>
                    </v-card-actions>
                  </v-sheet>
                </template>
                <v-card-actions class="pt-0 px-0" v-if="!packed">
                  <BaseActionButton
                    label="Remove Exercise"
                    class="pa-0 ml-n2"
                    title="Remove this exercise occurence from workout."
                    plain
                    small
                    color="orange"
                    icon="mdi-trash-can"
                    @clickedThis="setDeletable(exercise)"
                  />
                  <v-spacer />
                </v-card-actions>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-spacer />
          <v-card-actions class=" pb-3 px-0">
            <v-spacer />
            <BaseActionButton
              label="Finalize"
              large
              text
              class="paper--text mr-1"
              color="success"
              icon="mdi-arrow-down-box"
              @clickedThis="startFinalize"
            />
          </v-card-actions>
        </v-card>
      </template>
      <v-dialog
        max-width="800"
        v-model="showFinalizeDialog"
        :fullscreen="isPhone"
        persistent
        overlay-opacity=".95"
      >
        <v-card
          elevation="20"
          tile
          class="d-flex flex-column charcoal  paper--text noscroll-x"
          v-touch:swipe.left="cancelFinalize"
        >
          <BaseCloseDialogHeader
            @cancel="cancelFinalize"
            heading="Finalize Workout!"
          />
          <v-card-actions class="py-0 my-n5">
            <v-spacer />
            <v-checkbox
              class="pa-0"
              dark
              color="success"
              v-model="logActivity"
              label="Auto Save Activity?"
              :disabled="!enableRestTimers"
            />
          </v-card-actions>
          <v-sheet
            rounded
            :style="charcoalTile"
            class="charcoal silver--text mb-4 mx-2 py-2"
          >
            <v-card-title :class="textSize + ' paper--text pt-0 pl-2'">
              Summary
            </v-card-title>
            <v-card-text class="silver--text pa-0">
              <span v-if="duration != null" :class="textSize">
                <v-icon x-large color="silver" class="mt-n1">
                  mdi-clock-plus-outline
                </v-icon>
                <span class="ml-2">{{ duration }} minutes</span>
              </span>
              <v-spacer />
              <span v-if="calories > 0" :class="textSize">
                <v-icon x-large color="silver">
                  mdi-fire
                </v-icon>
                <span class="ml-2 ">{{ calories }} kCal</span>
              </span>
              <v-spacer />
              <span :class="textSizeSmall" v-if="workoutSets">
                <span class="largeLabel mt-2 pl-2">Volume: </span>
                {{ totalImperial.toLocaleString() }} lbs /
                {{ totalMetric.toLocaleString() }} kg<br />
                <span class="largeLabel pl-2">Effort: </span>
                {{ effort }} %
                <v-spacer />
                <span class="largeLabel pl-2">Sets & Reps </span>
                {{ workoutSets.length }} /
                {{
                  workoutSets.reduce(
                    (accum, item) => accum + parseInt(item.reps),
                    0
                  )
                }}
                <v-spacer />
                <span class="largeLabel pl-2">Exercises: </span>
                {{ exercises.length }}
              </span>
            </v-card-text>
          </v-sheet>
          <v-sheet
            rounded
            class="charcoal paper--text  mt-1 mx-2"
            :style="charcoalTile"
            v-if="prNumber > 0"
          >
            <v-card-text class="caption progressActive--text pb-1">
              Congratulations!! You broke some personal records!
            </v-card-text>
            <v-card-actions class="py-0">
              <v-spacer />
              <v-icon
                v-for="index in Math.min(prNumber, 5)"
                :key="index"
                size="50"
                color="gold"
              >
                mdi-trophy-award
              </v-icon>
              <v-spacer />
            </v-card-actions>
            <v-card-actions class="progressActive--text py-0">
              <v-spacer />

              <span :class="textSizeLarge">{{ prNumber }} </span>
              <v-spacer />
            </v-card-actions>
          </v-sheet>
          <v-sheet
            rounded
            class="charcoal paper--text  mt-1 mx-2"
            :style="charcoalTile"
            v-else
          >
            <v-card-actions class="caption progressActive--text pb-1">
              <v-spacer />Good job getting those reps in!<v-spacer />
            </v-card-actions>
            <v-card-actions class="py-0">
              <v-spacer />
              <v-icon size="100" color="gold">
                mdi-bullseye-arrow
              </v-icon>
              <v-spacer />
            </v-card-actions>
          </v-sheet>
          <v-spacer />
          <v-sheet class="charcoal py-2" rounded :style="charcoalTile">
            <v-card-text :class="textSizeXSmall + ' silver--text  pb-0 pt-2'">
              <span class="paper--text">Enter for previous day?</span>
              <v-spacer />
              You can adjust the start and end date & time for this workout
              before you finalize it.
            </v-card-text>
            <v-card-actions class="pb-0">
              <v-switch
                color="success"
                dark
                v-model="adjustTimes"
                label="Adjust Times?"
                @change="setTimesState"
              />
            </v-card-actions>
            <template v-if="adjustTimes">
              <v-card-actions class="py-0">
                <v-spacer />
                <v-datetime-picker
                  dark
                  label="Start Time"
                  v-model="workout.starttime"
                  :text-field-props="textFieldProps"
                  :date-picker-props="dateProps"
                  :time-picker-props="timeProps"
                  time-format="HH:mm:ss"
                  date-format="yyyy-MM-dd"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-card-actions>
              <v-card-actions class="py-0">
                <v-spacer />
                <v-datetime-picker
                  dark
                  label="End Time"
                  v-model="workout.endtime"
                  :text-field-props="textFieldProps"
                  :date-picker-props="dateProps"
                  :time-picker-props="timeProps"
                  time-format="HH:mm:ss"
                  date-format="yyyy-MM-dd"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-card-actions>
            </template>
          </v-sheet>
          <v-spacer />
          <v-card-actions class="pa-0">
            <v-btn
              tile
              block
              height="90"
              title="Finalize"
              tonal
              class=" charcoal--text "
              color="progressActive"
              @click="finalize"
            >
              <v-icon x-large color="blasck">
                mdi-arrow-down-box
              </v-icon>
              <span class="text-h5">Finalize</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showDeleteDialog"
        persistent
        :fullscreen="isPhone"
        :width="dialogWidth"
      >
        <v-card
          class="d-flex flex-column charcoal noscroll"
          v-if="selectedExercise != null"
        >
          <BaseCloseDialogHeader
            heading="Remove Exercise?"
            @cancel="showDeleteDialog = false"
          />
          <v-card rounded class="pa-2 transparent charcoalTile" flat>
            <v-card-text class="pa-0">
              <v-card-subtitle class="title paper--text">
                Exercise Name
                <div
                  class="body-1 silver--text"
                  v-html="selectedExercise.name"
                ></div>
              </v-card-subtitle>
              <v-card-subtitle :class="textSizeSmall + ' silver--text pt-5'">
                Click the <span class="progressActive--text">Delete</span>
                button to remove this exercise occurence from the workout..
              </v-card-subtitle>
            </v-card-text>
          </v-card>
          <v-spacer />
          <v-card-actions class="pa-0">
            <v-switch
              v-if="showSwitch"
              v-model="saveTemplate"
              color="progressActive"
            >
              <template v-slot:label>
                <span class="silver--text">Save Workout Template?</span>
              </template>
            </v-switch>
            <v-spacer />
            <BaseActionButton
              class="charcoal paper--text"
              label="Delete"
              icon="mdi-trash-can"
              plain
              :large="!isPhone"
              @clickedThis="removeExercise(selectedExercise)"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        :width="dialogWidth"
        :value="showEditDefaults"
        permanent
        transition="dialog-bottom-transition"
        overlay-color="charcoal"
        overlay-opacity="0.95"
        :fullscreen="isPhone"
      >
        <v-card
          v-if="selectedExercise != null"
          tile
          class="d-flex flex-column charcoal noscroll"
        >
          <BaseCloseDialogHeader
            heading="Set Default Reps & Weight"
            @cancel="showEditDefaults = false"
          />
          <template v-if="selectedExercise != null">
            <v-card
              rounded
              elevation="5"
              class="charcoal charcoalTileMenu paper--text pa-2 mx-2 link"
              @click="useLastLifts(selectedExercise)"
            >
              <v-card-actions class="py-0 pr-0">
                <span :class="textSize">
                  Use The Last Lift Numbers
                </span>
                <v-spacer />
                <v-icon
                  large
                  class="pa-1 pr-0"
                  color="progressActive"
                  title="Use recommended reps and weights as defined by the rep scheme"
                >
                  mdi-gesture-tap
                </v-icon>
              </v-card-actions>
              <v-card-text class="pa-0 pl-2">
                <span class="caption silver--text">
                  This will use for each set in this exercise the same lift
                  numbers used the last time you performed this workout.
                </span>
              </v-card-text>
            </v-card>
            <v-card-actions :class="textSize + ' py-0 paper--text'">
              <v-spacer />
              OR
              <v-spacer />
            </v-card-actions>
          </template>

          <v-card
            rounded
            elevation="5"
            class="charcoal charcoalTileMenu paper--text pa-2 mx-2 link"
            @click="useRepScheme"
          >
            <v-card-actions class="py-0 pr-0">
              <span :class="textSize">
                Use The RepScheme
              </span>
              <v-spacer />
              <v-icon
                large
                class="pa-1 pr-0"
                color="progressActive"
                title="Use recommended reps and weights as defined by the rep scheme"
              >
                mdi-gesture-tap
              </v-icon>
            </v-card-actions>
            <v-card-text class="pa-0 pl-2">
              <span class="caption silver--text">
                The exercise repscheme will set the weight and reps. exercise.
              </span>
            </v-card-text>
          </v-card>

          <v-spacer />
          <template v-if="selectedExercise != null">
            <v-card-actions :class="textSize + ' paper--text py-0'">
              <v-spacer />
              OR
              <v-spacer />
            </v-card-actions>
            <v-card
              rounded
              elevation="1"
              class="charcoal charcoalTileMenu paper--text pa-2 mx-2 link"
            >
              <v-card-actions class="py-0 pr-0">
                <span :class="textSize">
                  Manually
                </span>
                <v-spacer />
                <v-icon
                  large
                  class="pa-1 pr-0"
                  color="progressActive"
                  title="Use recommended reps and weights as defined by the rep scheme"
                >
                  mdi-arrow-down
                </v-icon>
              </v-card-actions>
              <v-card-text class="pa-0 pl-2">
                <span class="caption silver--text">
                  Use the values recommended based on the select 1RM percentage,
                  or manually select weight and reps.
                </span>
              </v-card-text>
            </v-card>
          </template>
          <v-spacer />
          <v-card-actions :class="textSizeLarge + ' progressActive--text pt-0'">
            <v-spacer />
            1RM:
            {{
              metric
                ? selectedExercise.repmax_metric
                : selectedExercise.repmax_imperial
            }}
            {{ unit(metric) }}
            <v-spacer />
          </v-card-actions>

          <v-card-actions :class="textSizeXSmall + ' mx-2 px-2 pt-8 pb-2'">
            <v-slider
              dark
              v-model="selectedPercent"
              :label="'Use ' + selectedPercent + '% of 1RM'"
              thumb-color="progressActive"
              thumb-label="always"
              step="5"
              ticks="always"
              tick-size="7"
              :max="maxPercent"
              :min="minPercent"
              @change="calculateDefaultWeight"
            />
          </v-card-actions>
          <v-card-actions class="pa-0">
            <v-row class="mr-2 ml-1">
              <v-col cols="5">
                <v-text-field
                  dark
                  class="enlarged-input-small"
                  ref="defaultReps"
                  type="number"
                  label="Reps"
                  v-model="selectedExercise.default_reps"
                  @input="manualChange = true"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-col cols="5">
                <v-text-field
                  dark
                  class="enlarged-input-small"
                  ref="defaultWeight"
                  type="number"
                  :label="'Weight(' + unit(metric) + ')'"
                  v-model="selectedExercise.default_weight"
                  @focus="$event.target.select()"
                />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions class="pa-0">
            <v-btn
              tile
              block
              height="90"
              tonal
              class=" charcoal--text "
              color="progressActive"
              title="Done"
              @click="doneSetDefaultReps(selectedExercise)"
            >
              <v-icon x-large color="charcoal">
                mdi-checkbox-multiple-marked-circle
              </v-icon>
              <span class="text-h5 mb-1 ml-1">DONE</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent scrollable fullscreen v-model="showRestDialog">
        <v-card
          color="charcoal"
          tile
          flat
          class="noscroll d-flex flex-column"
          v-touch:swipe.left="cancelTimers"
        >
          <BaseCloseDialogHeader
            heading="Workout Timer"
            @cancel="cancelTimers"
          />
          <v-row dense justify="center" :class="isPhone ? 'mt-n2' : 'mt-n1'">
            <v-img
              :src="imageLogo"
              contain
              title="Logo"
              class="visible"
              :height="isPhone ? 40 : 60"
            />
          </v-row>
          <v-sheet rounded class="video charcoal">
            <video-background
              :src="videoLoop"
              style=" height: 8vh;opacity:0.65"
              overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
            >
            </video-background>
          </v-sheet>
          <v-row dense justify="center" align="center">
            <v-spacer />
            <span :class="textSize + ' mx-2 silver--text'">
              {{ currentExercise.name }}
            </span>
            <v-spacer />
          </v-row>
          <v-row justify="center" class="pb-2 mt-n1">
            <BaseIconCounter
              :rounds="currentExerciseSets"
              :current="currentSet + 1"
              :dark="true"
            />
          </v-row>

          <v-sheet class="transparent rounded pa-2 mx-auto text-center ">
            <span
              :class="textSizeSmall + ' gold--text text--darken-1 text-center'"
            >
              <span class="silver--text" v-if="timer_stage == 'resting'">
                REST TIME {{ activeSet.rest }}
              </span>
              <span class="silver--text" v-if="timer_stage == 'working'">
                SET {{ activeSet.setnum }}
              </span>
            </span>
          </v-sheet>
          <v-row justify="center" class="pt-5">
            <v-progress-circular
              v-if="value >= 0"
              :rotate="-90"
              :size="circleSize"
              :width="circleWidth"
              :value="
                timer_stage == 'working'
                  ? 100
                  : 100 - value * (100 / setRestTime)
              "
              :color="
                timer_stage == 'working'
                  ? 'gold'
                  : remaining > 0
                  ? 'progressActive'
                  : 'silver'
              "
              class="link"
              @click="stageDone(activeSet)"
            >
              <v-card-text class="text-center text-uppercase">
                <span v-if="timer_stage == 'resting'">
                  Rest Time Remaining
                </span>
                <span v-if="timer_stage == 'working'">
                  Active Time
                </span>
                <span v-if="timer_stage == 'ready'">
                  Get Ready!
                </span>
                <v-divider class="charcoal lighten-2 mt-5" />
                <span :class="textSizeXXLarge + ' py-3'">
                  {{ timer_stage == 'working' ? activeSet.active : remaining }}
                </span>
                <v-divider class="charcoal lighten-2" />
                <v-spacer />
              </v-card-text>
            </v-progress-circular>
          </v-row>
          <template>
            <v-spacer />
            <template v-if="timer_stage != 'working'">
              <v-card-actions :class="textSize + ' pt-0 silver--text'">
                <v-divider class="charcoal lighten-1 mr-1" />
                <span> +/- TIME (seconds) </span>
                <v-divider class="charcoal lighten-1 ml-1" />
              </v-card-actions>
              <v-row justify="center" class="pt-1">
                <BaseActionButton
                  dark
                  :disabled="remaining <= 5"
                  text
                  x-large
                  label="+15"
                  icon="mdi-update"
                  color="progressActive"
                  @clickedThis="addTime(15)"
                />
                <BaseActionButton
                  dark
                  :disabled="remaining <= 15"
                  text
                  x-large
                  label="-15"
                  icon="mdi-history"
                  color="gold"
                  @clickedThis="addTime(-15)"
                />
              </v-row>
              <v-row justify="center" class="pt-2">
                <BaseActionButton
                  dark
                  :disabled="remaining <= 5"
                  text
                  x-large
                  label="+30"
                  icon="mdi-update"
                  color="progressActive"
                  @clickedThis="addTime(30)"
                />
                <BaseActionButton
                  dark
                  :disabled="remaining <= 30"
                  text
                  x-large
                  label="-30"
                  icon="mdi-history"
                  color="gold"
                  @clickedThis="addTime(-30)"
                />
              </v-row>
              <v-row justify="center" class="pt-2">
                <BaseActionButton
                  dark
                  :disabled="remaining <= 5"
                  text
                  x-large
                  label="+60"
                  icon="mdi-update"
                  color="progressActive"
                  @clickedThis="addTime(60)"
                />
                <BaseActionButton
                  dark
                  :disabled="remaining <= 60"
                  text
                  x-large
                  label="-60"
                  icon="mdi-history"
                  color="gold"
                  @clickedThis="addTime(-60)"
                />
              </v-row>
            </template>
            <template v-else>
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="4">
                  <v-text-field
                    dark
                    :class="isPhone ? 'enlarged-input-small' : 'enlarged-input'"
                    filled
                    color="success"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    label="Reps"
                    v-model="activeSet.reps"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <template v-if="currentExercise.resistance == 'Yes'">
                  <v-col cols="4">
                    <v-text-field
                      :disabled="metric"
                      dark
                      :class="
                        isPhone ? 'enlarged-input-small' : 'enlarged-input'
                      "
                      filled
                      color="success"
                      type="number"
                      pattern="[0-9]*"
                      inputmode="decimal"
                      label="Lbs"
                      v-model="activeSet.imperial"
                      @input="
                        calculateMetric(activeSet, currentExercise.bilateral)
                      "
                      @focus="$event.target.select()"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      :disabled="!metric"
                      dark
                      :class="
                        isPhone ? 'enlarged-input-small' : 'enlarged-input'
                      "
                      filled
                      color="success"
                      type="number"
                      pattern="[0-9]*"
                      inputmode="decimal"
                      label="Kg"
                      v-model="activeSet.metric"
                      @input="
                        calculateImperial(activeSet, currentExercise.bilateral)
                      "
                      @focus="$event.target.select()"
                    />
                  </v-col>
                </template>
              </v-row>
              <v-btn
                x-large
                title="Done Working?"
                block
                text
                :style="charcoalTileMenu"
                class="charcoal gold--text fullbtn"
                color="paper"
                @click="stageDone(activeSet)"
              >
                <v-icon color="gold" large>
                  mdi-checkbox-multiple-marked-circle
                </v-icon>
                <span class=" ml-1">
                  DONE WORKING?
                </span>
              </v-btn>
            </template>
            <v-btn
              v-if="timer_stage == 'resting'"
              x-large
              title="Done Resting?"
              block
              text
              :style="charcoalTileMenu"
              class="charcoal paper--text fullbtn"
              color="paper"
              @click="stageDone(activeSet)"
            >
              <v-icon color="paper" large>
                mdi-checkbox-multiple-marked-circle
              </v-icon>
              <span class="ml-1">
                DONE RESTING?
              </span>
            </v-btn>
          </template>
        </v-card>
      </v-dialog>
      <LoadingDialog
        :show="!loaded"
        title="PREPARING WORKOUT..."
        color="accent"
      />
      <v-dialog
        :width="dialogWidth"
        v-model="showExerciseInfo"
        :fullscreen="isPhone"
        overlay-opacity=".97"
      >
        <ExerciseInfo
          :exerciseid="exerciseid"
          @keydown.esc="cancelInfo"
          @cancel="cancelInfo"
          @swipe_off="cancelInfo"
        />
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import sounds from '@/json/sounds.json'
import moment from 'moment/src/moment'

const LoadingDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Loading.vue')
const ExerciseInfo = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ExerciseInfo.vue')
const WeightPrompt = () => import('@/components/dialogs/WeightPrompt.vue')
export default {
  mixins: [util],
  name: 'StartWorkout',
  components: {
    ExerciseInfo,
    LoadingDialog,
    WeightPrompt
  },
  data: () => ({
    textFieldProps: {
      appendIcon: 'mdi-calendar',
      dark: true
    },
    dateProps: {
      headerColor: 'charcoal',
      color: 'progressActive'
    },
    timeProps: {
      scrollable: true,
      headerColor: 'charcoal',
      color: 'progressActive',
      useSeconds: false,
      ampmInTitle: true
    },
    mode: 'add',
    sounds: sounds,
    value: 0,
    remaining: 0,
    activeMinutes: 0,
    selectedPercent: 70,
    maxPercent: 100,
    minPercent: 50,
    adjustTimes: false,
    showFinalizeDialog: false,
    showAddMore: false,
    showRestDialog: false,
    showDeleteDialog: false,
    showExerciseInfo: false,
    saveTemplate: true,
    showSwitch: false,
    starttime: null,
    endtime: null,
    restStart: null,
    restEnd: null,
    duration: null,
    finalStartTime: null,
    restTimerInterval: {},
    workTimerInterval: {},
    setRestTime: 0,
    workout: [],
    exercises: [],
    repSchemes: [],
    workoutSets: [],
    cardioSets: [],
    lastLifts: [],
    repmaxes: [],
    interval: {},
    instance: {},
    exerciseid: '',
    selectedExercise: null,
    workoutinstanceid: '',
    clock: '',
    ago: '',
    metric: false,
    manualChange: false,
    packed: true,
    timeout: 2000,
    tic: 1000,
    totalMetric: 0,
    totalImperial: 0,
    totalCardioTime: 0,
    totalRest: 0,
    totalActive: 0,
    effort: 0,
    repSchemeDefault: true,
    showEditDefaults: false,
    logActivity: true,
    switchNum: 0,
    loaded: false,
    prestart: false,
    currentExercise: {},
    currentExerciseSets: 0,
    currentSet: 1,
    basic: {},
    activeSet: {},
    activityid: 42, //weight lifting
    mets: 5,
    calories: 0,
    prNumber: 0,
    audio: null,
    beep: null,
    readygo: null,
    videoLoop: null,
    imageLogo: null,
    timer_stage: 'ready',
    get_ready_default: 15,
    enableRestTimers: true
  }),
  props: {
    workoutid: {
      type: String
    }
  },
  beforeMount() {
    if (localStorage.getItem('startWorkout') == 'false') {
      this.$router.push('/myworkouts')
    } else {
      localStorage.setItem('startWorkout', false)
      this.audio = new Audio(this.sounds['5secs'])
      this.beep = new Audio(this.sounds['ready'])
      this.readygo = new Audio(this.sounds['readygo'])
      this.audio.preload = 'auto'
      this.beep.preload = 'auto'
      this.readygo.preload = 'auto'
      this.videoLoop = this.isPhone
        ? require(`@/assets/LoopSmall.mp4`)
        : require(`@/assets/Loop.mp4`)
      this.imageLogo = require('@/assets/Logo100.png')

      this.loadBasic()
      this.startUp()
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
    clearInterval(this.restTimerInterval)
    clearInterval(this.workTimerInterval)
    window.removeEventListener('timer_stage', this.receiveMessage)
  },
  mounted() {
    let enableRestTimers = localStorage.getItem('restTimersOn')
    if (!enableRestTimers) {
      this.enableRestTimers = true
    } else {
      this.enableRestTimers = enableRestTimers == 'Yes'
    }

    this.workout.starttime = this.now()
    this.updatePackedWorkout(this.workout)

    this.activeMinutes = 0
    window.addEventListener('timer_stage', this.receiveMessage)
    this.scrollToTop()
  },
  computed: {
    circleSize() {
      if (this.isPhone) return 230
      else return 370
    },
    circleWidth() {
      return 10
    },
    ...appConfig
  },
  watch: {
    showRestDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
      if (value) {
        this.screenOn()
      } else this.screenOff()
    },
    showDeleteDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    showExerciseInfo: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    showFinalizeDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    }
  },
  methods: {
    saveRestTimerChoice() {
      this.logActivity = this.enableRestTimers
      let val = this.enableRestTimers ? 'Yes' : 'No'
      localStorage.setItem('restTimersOn', val)
    },
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showRestDialog) this.showRestDialog = false
        else if (this.showDeleteDialog) this.showDeleteDialog = false
        else if (this.showExerciseInfo) this.showExerciseInfo = false
        else if (this.showFinalizeDialog) this.showFinalizeDialog = false
      }
    },
    cancelTimers() {
      this.timer_stage = 'ready'
      this.resetRestTimer()
      this.resetWorkRestTimer()
      this.totalRest += this.activeSet.rest
      this.totalActive += this.activeSet.active
      this.showRestDialog = false
      this.prestart = false
      this.value = 0
      this.remaining = 0
      this.screenOff()
    },
    stageDone(set) {
      if (this.timer_stage == 'working') {
        this.timer_stage = 'resting'
        this.saveSet(set)
        this.startRestCounter(set)
        this.calculateVolume(set, this.currentExercise.bilateral)
      } else if (this.timer_stage == 'resting') {
        this.saveSetTimes()
        this.cancelTimers()
      } else this.resetRestTimer()
    },
    doneGetReady(set) {
      this.resetWorkRestTimer()
      this.timer_stage = 'working'
      this.startWorkCounter(set)
    },
    startGetReady(set) {
      this.setCurrentSetInfo(set)
      this.activeSet = set
      this.timer_stage = 'ready'
      this.showRestDialog = true
      this.remaining = this.get_ready_default
      this.value = this.get_ready_default
      this.setRestTime = this.get_ready_default
      this.prestart = true

      this.workTimerInterval = setInterval(() => {
        if (this.remaining == 6 || this.remaining == 11) {
          this.playDing()
        }
        if (this.remaining == 2) {
          this.playStart()
        }
        if (this.remaining == 0) {
          this.doneGetReady(set)
        } else {
          this.remaining--
        }
        this.value = this.remaining
      }, this.tic)
    },

    startWorkCounter(set) {
      this.resetRestTimer()
      this.resetWorkRestTimer()
      this.timer_stage = 'working'
      set.active = 0
      this.activeSet = set
      this.value = set.active

      let workStart = moment()
      this.elapsedSeconds = moment().diff(workStart, 'seconds')
      this.elapsedMinutes = moment().diff(workStart, 'minutes')

      this.restTimerInterval = setInterval(() => {
        if (this.value % 30 == 0) {
          this.playDing()
        }

        set.active = moment().diff(workStart, 'seconds')

        this.value = set.active
      }, this.tic)
    },

    startRestCounter(set) {
      this.resetRestTimer()
      this.resetWorkRestTimer()
      this.screenOn()
      set.rest = 0
      this.activeSet = set

      this.showRestDialog = true
      this.remaining = this.setRestTime
      this.value = this.setRestTime

      this.restStart = moment()
      this.elapsedSeconds = moment().diff(this.restStart, 'seconds')
      this.elapsedMinutes = moment().diff(this.restStart, 'minutes')

      this.restTimerInterval = setInterval(() => {
        if (this.remaining == this.soundCutoff) {
          this.playEnd()
        } else if (
          this.remaining > this.soundCutoff + 12 &&
          this.remaining % 30 == 0
        ) {
          this.playDing()
        }
        set.rest++

        if (this.remaining <= 0) {
          this.remaining = 0
        } else {
          this.remaining =
            parseInt(this.setRestTime) -
            moment().diff(this.restStart, 'seconds')
        }
        this.value = this.remaining
      }, this.tic)
    },

    startSet(set) {
      if (
        !(set.distance_metric > 0) &&
        set.saved != 'Yes' &&
        this.enableRestTimers
      ) {
        this.startGetReady(set)
      } else this.saveSet(set)
    },

    showWeightPromt() {
      return (
        this.basic == null ||
        this.basic.weight == null ||
        this.basic.height == null ||
        (this.sex != 'Male' && this.sex != 'Female')
      )
    },
    doneSetDefaultReps(selectedExercise) {
      this.repSchemeDefault = false
      this.showEditDefaults = false
      this.copyReps(selectedExercise)
    },
    useRepScheme() {
      this.repSchemeDefault = true
      this.copyReps(this.selectedExercise)
    },
    setDeletable(exercise) {
      this.selectedExercise = exercise
      this.showDeleteDialog = true
    },
    startFinalize() {
      this.prExceeded()
      this.showFinalizeDialog = true
      this.workout.endtime = this.now()

      this.duration = this.inMinutes(
        this.workout.endtime,
        this.workout.starttime
      )
    },
    prExceeded() {
      let records = []
      this.workoutSets.forEach(function(e) {
        if (e.effort > 100) {
          if (records.indexOf(e.exerciseid) < 0) records.push(e.exerciseid)
        }
      })
      this.prNumber = records.length
    },
    cancelFinalize() {
      this.showFinalizeDialog = false
      this.adjustTimes = false
    },
    startUp() {
      this.loaded = false
      clearInterval(this.interval)
      this.resetWorkRestTimer()
      this.resetRestTimer()
      this.loadRepschemes()
      this.loadUserRepmaxes()
      this.startWorkout(this.workoutid)
    },
    done() {
      this.loadBasic()
      this.startUp()
    },
    showExerciseInfoDialog(exerciseid) {
      this.exerciseid = exerciseid
      this.switchNum++
      this.showExerciseInfo = true
    },

    cancelInfo() {
      this.exerciseid = null
      this.showExerciseInfo = false
    },
    setTimesState() {
      if (!this.adjustTimes) {
        this.workout.endtime = this.now()
      }
    },
    changeDefault(exercise) {
      this.selectedExercise = exercise
      this.calculateDefaultWeight()
      this.showEditDefaults = true
    },
    startTimer() {
      this.interval = setInterval(() => {
        this.clock = this.prettyNow()
        this.ago = this.fromNow(this.workout.starttime)

        this.activeMinutes = this.twoDigits(this.totalActive / 60)
        this.calories = this.calculateCalories(
          this.mets,
          this.weightMetric,
          this.activeMinutes
        )
      }, this.tic)
    },
    playEnd() {
      if (this.isIOS()) this.playEndNative()
      else this.audio.play()
    },
    playStart() {
      if (this.isIOS()) this.playStartNative()
      else this.readygo.play()
    },
    playDing() {
      if (this.isIOS()) this.playDingNative()
      else this.beep.play()
    },

    resetRestTimer() {
      this.remaining = 0
      clearInterval(this.restTimerInterval)
    },
    resetWorkRestTimer() {
      this.remaining = 0
      clearInterval(this.workTimerInterval)
    },
    addTime(val) {
      this.setRestTime += val
      this.value += val
      this.remaining += val
    },

    effortColor(exercise, set, light) {
      let color = light ? 'charcoal' : 'silver'
      let comparable = null
      if (set == null) {
        comparable = this.metric
          ? exercise.newRM.oneRMMetric
          : exercise.newRM.oneRMImperial
      } else if (set.calcRM) {
        comparable = this.metric
          ? set.calcRM.oneRMMetric
          : set.calcRM.oneRMImperial
      }

      if (comparable > this.getRM(exercise)) color = 'success'
      return color
    },
    rmColor(exercise, set) {
      let color = 'silver'
      let comparable = null
      if (set == null) {
        comparable = this.metric
          ? exercise.newRM.oneRMMetric
          : exercise.newRM.oneRMImperial
      } else {
        comparable = this.metric
          ? set.calcRM.oneRMMetric
          : set.calcRM.oneRMImperial
      }
      if (comparable > this.getRM(exercise)) color = 'progressActive'
      else if (comparable < this.getRM(exercise)) color = 'silver'
      return color
    },
    useLastLifts(exercise) {
      this.loadLastLifts(exercise)
      this.showEditDefaults = false
    },
    copyReps(exercise) {
      let t = this
      let val = this.workoutSets.filter(function(e) {
        return e.exerciseid == exercise.id
      })
      val.forEach(function(element) {
        let repscheme = exercise.details[element.setnum - 1]
        if (!t.repSchemeDefault) element.reps = exercise.default_reps
        else element.reps = repscheme.reps

        if (!t.metric) {
          if (!t.repSchemeDefault) {
            element.imperial = exercise.default_weight
            element.metric = t.twoDigits(exercise.default_weight * t.factor)
          } else {
            element.imperial =
              t.roundFive((repscheme.load * exercise.repmax_imperial) / 100) +
              repscheme.load_increment
            element.metric =
              t.twoDigits((repscheme.load * exercise.repmax_metric) / 100) +
              repscheme.load_increment
          }
        } else {
          if (!t.repSchemeDefault) {
            element.metric = exercise.default_weight
            element.imperial = t.twoDigits(exercise.default_weight / t.factor)
          } else {
            element.imperial =
              t.twoDigits((repscheme.load * exercise.repmax_imperial) / 100) +
              repscheme.load_increment
            element.metric =
              t.roundFive((repscheme.load * exercise.repmax_metric) / 100) +
              repscheme.load_increment
          }
        }
        t.calculateVolume(element, exercise.bilateral)
      })
      this.showEditDefaults = false
    },
    getRM(exercise) {
      if (!exercise.repmax_metric > 0) return 0
      return this.metric ? exercise.repmax_metric : exercise.repmax_imperial
    },
    getRMCalc(exercise) {
      if (exercise.default_weight > 0) return exercise.default_weight
      let ret = this.getRM(exercise)
      ret = this.roundFive((ret * exercise.pmax) / 100)
      return ret
    },
    resetTotalExercises() {
      this.exercises.forEach(function(element) {
        element.total_metric = 0
        element.total_imperial = 0
      })
    },
    calculateDefaultWeight() {
      let percent = this.selectedPercent / 100
      let weight = this.metric
        ? this.selectedExercise.repmax_metric
        : this.selectedExercise.repmax_imperial

      this.selectedExercise.default_weight = !this.metric
        ? this.roundFive(weight * percent)
        : this.roundToNearest2Point5(weight * percent)

      if (!this.manualChange) {
        if (this.selectedPercent == 100) {
          this.selectedExercise.default_reps = 1
        } else if (this.selectedPercent > 95) {
          this.selectedExercise.default_reps = 2
        } else if (this.selectedPercent == 95) {
          this.selectedExercise.default_reps = 3
        } else if (this.selectedPercent >= 90) {
          this.selectedExercise.default_reps = 4
        } else if (this.selectedPercent >= 85) {
          this.selectedExercise.default_reps = 6
        } else if (this.selectedPercent >= 80) {
          this.selectedExercise.default_reps = 8
        } else if (this.selectedPercent >= 75) {
          this.selectedExercise.default_reps = 10
        } else if (this.selectedPercent >= 70) {
          this.selectedExercise.default_reps = 12
        } else if (this.selectedPercent >= 65) {
          this.selectedExercise.default_reps = 15
        } else if (this.selectedPercent >= 60) {
          this.selectedExercise.default_reps = 18
        } else if (this.selectedPercent >= 50) {
          this.selectedExercise.default_reps = 22
        }
      }
    },
    calculateTotals() {
      if (!this.workoutSets) return
      let total = 0
      let effort = 0
      let totalImp = 0
      let time = 0
      let totalRest = 0
      let totalActive = 0
      this.recalculateRM()
      this.workoutSets.forEach(el => {
        total = parseFloat(el.volume_metric) + total
        totalRest = parseInt(el.rest) + totalRest
        totalActive = parseInt(el.active) + totalActive
        totalImp = parseFloat(el.volume_imperial) + totalImp
        if (el.saved == 'Yes') time = parseFloat(el.time) + time
        el.pace_metric = this.twoDigits(el.time / el.distance_metric)
        el.pace_imperial = this.twoDigits(el.time / el.distance_imperial)
        el.speed_metric = this.twoDigits((el.distance_metric * 60) / el.time)
        el.speed_imperial = this.twoDigits(
          (el.distance_imperial * 60) / el.time
        )

        this.exercises.forEach(ex => {
          if (el.exerciseid == ex.id) {
            ex.total_metric = parseFloat(el.volume_metric) + ex.total_metric
            ex.total_imperial =
              parseFloat(el.volume_imperial) + ex.total_imperial
            ex.total_metric = this.twoDigits(ex.total_metric)
            ex.total_imperial = this.twoDigits(ex.total_imperial)
          }
        })
      })
      let withData = this.workoutSets.filter(function(el) {
        return el.reps > 0 && el.metric > 0 && el.effort > 0
      })
      if (withData.length > 0) {
        withData.forEach(function(el) {
          effort += el.effort
        })
        this.effort = this.twoDigits(effort / withData.length)
      } else this.effort = 0.0

      this.totalMetric = this.twoDigits(total)
      this.totalImperial = this.twoDigits(totalImp)
      this.totalCardioTime = time

      this.totalRest = totalRest
      this.totalActive = totalActive
    },
    getExerciseRepmax(exerciseid) {
      let t = this
      let val = 0
      this.exercises.forEach(ex => {
        if (exerciseid == ex.id) {
          if (t.metric) val = ex.repmax_metric
          else val = ex.repmax_imperial
        }
      })
      return val
    },
    recalculateRM() {
      if (!this.workoutSets) return
      let weight = 0
      let t = this
      let warn = false
      this.workoutSets.forEach(el => {
        if (el.metric > 0 && el.reps > 0) {
          weight = t.metric ? el.metric : el.imperial
          el.calcRM = this.calculateRM(el.reps, weight, t.metric)
          let exRepmax = t.getExerciseRepmax(el.exerciseid)
          if (!(exRepmax > 0)) el.effort = 0.0
          else
            el.effort = t.twoDigits(
              parseFloat(el.calcRM.oneRM / exRepmax) * 100
            )
        } else {
          el.calcRM = { oneRM: 0 }
          el.effort = 0
        }
        if (el.effort > 200) {
          if (!el.warned) {
            warn = true
            el.warned = true
          }
        }
      })
      if (warn) {
        this.toast(
          'Calculated effort is greater than 200%. Please double check the entered number of reps and weight!',
          4000,
          'info'
        )
      }
      this.exercises.forEach(ex => {
        let exInstances = this.workoutSets.filter(function(el) {
          return el.exerciseid == ex.id
        })

        if (exInstances) {
          ex.calcRMMax = Math.max(...exInstances.map(o => o.calcRM.oneRM), 0)
          let newRMObj = this.getRMObject(ex.calcRMMax, this.metric)
          ex.newRM = newRMObj
        }

        let withData = exInstances.filter(function(el) {
          return el.reps > 0 && el.metric > 0
        })

        //calculate avg effort for this exercise
        ex.effort = t.twoDigits(
          parseFloat(
            withData.reduce((r, c) => r + c.effort, 0) / withData.length
          )
        )
      })
    },
    calculateVolume(set, bilateral) {
      let coef = bilateral == 'Yes' ? 2 : 1
      set.volume_metric = coef * set.reps * set.metric
      set.volume_metric = this.twoDigits(set.volume_metric)
      set.volume_imperial = coef * set.reps * set.imperial
      set.volume_imperial = this.twoDigits(set.volume_imperial)

      this.calculateTotals()
    },
    calculateImperial(set, bilateral) {
      if (set.distance_metric > 0) {
        set.distance_imperial = this.threeDigits(set.distance_metric / 1.60934)
      }
      set.imperial = set.metric * (1 / this.factor)
      set.metric = this.oneDigit(set.metric)
      set.imperial = this.oneDigit(set.imperial)
      this.calculateVolume(set, bilateral)
    },
    calculateMetric(set, bilateral) {
      if (set.distance_imperial > 0) {
        set.distance_metric = this.threeDigits(set.distance_imperial * 1.60934)
      }
      set.metric = set.imperial * this.factor
      set.metric = this.oneDigit(set.metric)
      set.imperial = this.oneDigit(set.imperial)

      this.calculateVolume(set, bilateral)
    },

    loadRepschemes() {
      this.repSchemes = JSON.parse(localStorage.getItem('repschemes'))
    },
    loadRepscheme(exercise) {
      let scheme = this.repSchemes.filter(item => {
        return item.id == exercise.repschemeid
      })
      exercise.repscheme = scheme[0]
      exercise.sets = scheme[0].sets

      exercise.details = JSON.parse(scheme[0].details)
      let i = 0
      this.workoutSets.forEach(el => {
        if (el.exerciseid == exercise.id) {
          i++
          let detailsLen = exercise.details.length

          if (i > detailsLen) {
            let index = i - 2

            exercise.details[index].setnum++
            let newDetails = exercise.details[index]
            newDetails.id = newDetails.id.concat(index)
            exercise.details.push(newDetails)
          }
        }
      })
    },
    loadUserRepmaxes() {
      this.repmaxes = JSON.parse(localStorage.getItem('repmaxes'))
    },
    loadWorkout(workoutid) {
      {
        let available = localStorage.getItem('workouts_packed')
        available = JSON.parse(available)
        available = available.filter(
          w => w.workoutid == workoutid && w.userid == this.userid
        )
        this.workout = available[0]
        this.exercises = this.workout.exercises
        this.workoutSets = this.workout.sets
        let t = this
        this.exercises.forEach(function(exercise) {
          t.loadRepscheme(exercise)
          let repmaxval = t.repmaxes.filter(e => {
            return e.exerciseid == exercise.id
          })[0]
          exercise.repmax_imperial = repmaxval ? repmaxval.imperial : 0
          exercise.repmax_metric = repmaxval ? repmaxval.metric : 0
          exercise.default_weight = 0
          exercise.default_reps = 0
          exercise.saved = 'No'
          exercise.total_metric = 0
          exercise.total_imperial = 0
          exercise.time = 0
          exercise.distance_metric = 0
          exercise.distance_imperial = 0
          exercise.effort = 0
          exercise.set = []
          for (let i = 1; i <= exercise.sets; i++) {
            exercise.set.push({ num: i, metric: 0, imperial: 0, reps: 0 })
          }
        })

        this.loaded = true
      }
    },
    loadInstance() {
      if (this.workout.starttime == null) this.workout.starttime = this.now()
      this.startTimer()
      this.loaded = true
    },
    loadLastLifts(exercise) {
      this.lastLifts = this.workout.lastLifts
      let t = this
      this.workoutSets.forEach(el => {
        const lastLift = this.lastLifts.find(
          lift => lift.exerciseid == el.exerciseid && lift.setnum == el.setnum
        )
        if (lastLift) {
          el.imperial = lastLift.imperial
          el.metric = lastLift.metric
          el.reps = lastLift.reps
          el.time = lastLift.time
          el.distance_metric = lastLift.distance_metric
          el.distance_imperial = lastLift.distance_imperial
          t.calculateVolume(el, exercise.bilateral)
        }
      })
      this.calculateTotals()
    },
    startWorkout(workoutid) {
      this.loadWorkout(workoutid)
      this.workoutinstanceid = this.workout.workoutinstanceid
      this.calculateTotals()
      this.loadInstance()
    },
    saveOneRM(exercise) {
      return axios
        .post(this.baseURL + '/exercises/repmax', {
          id: exercise.id,
          metric: exercise.newRM.oneRMMetric,
          imperial: exercise.newRM.oneRMImperial,
          metricfive: exercise.newRM.oneRMFiveMetric,
          imperialfive: exercise.newRM.oneRMFiveImperial,
          workoutinstanceid: this.workoutinstanceid,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('1RM Saved For: ' + exercise.name)
            this.loadCurrent()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    finalize() {
      let endtime = this.adjustTimes ? this.endtime : this.now()
      this.duration = this.inMinutes(endtime, this.workout.starttime)

      this.workout.volume_metric = this.totalMetric
      this.workout.volume_imperial = this.totalImperial
      this.workout.effort = this.effort
      this.workout.duration = this.duration
      this.workout.total_rest = this.totalRest
      this.workout.total_active = this.totalActive
      this.workout.total_cardio_time = this.totalCardioTime
      this.workout.cardio_sets = this.workout.sets.filter(el => {
        return el.time > 0 && el.distance_metric > 0
      })
      this.workout.log_activity = this.logActivity
      this.workout.now = this.now()
      this.queueWorkout(this.workout)
      this.deletePackedWorkout(this.workout)
      this.showFinalizeDialog = false
      this.toast('Finalizing workout..')
      this.$router.push({
        path: '/'
      })
    },

    saveSetTimes() {
      this.workout.sets = this.workoutSets
      this.updatePackedWorkout(this.workout)
    },
    setCurrentSetInfo(set) {
      let setExercise = this.exercises.filter(function(el) {
        return el.id == set.exerciseid
      })
      let exercise = setExercise[0]
      let sets = exercise.details.length
      let details = exercise.details
      let currSet = details.find(el => el.setnum == set.setnum)
      this.setRestTime = currSet ? currSet.rest : 120

      this.currentExercise = exercise
      this.currentSet = set.setnum
      this.currentExerciseSets = sets
    },
    saveSet(set) {
      this.setCurrentSetInfo(set)
      set.color = 'progressActive'
      set.saved = 'Yes'
      set['now'] = this.now()
      this.workout.sets = this.workoutSets
      this.updatePackedWorkout(this.workout)
      this.toast('Set was updated succesfully.')
    },
    removeExercise(exercise) {
      return axios
        .post(this.baseURL + '/workout/removeexercise', {
          exerciseid: exercise.id,
          sort: exercise.sort,
          workoutinstanceid: this.workoutinstanceid,
          workoutexerciseid: exercise.workoutexerciseid,
          workoutid: this.workoutid,
          saveTemplate: this.saveTemplate
        })
        .then(response => {
          if (response.status == 200) {
            this.startWorkout(this.workoutid)
            this.showDeleteDialog = false
            this.toast('Exercise removed..')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    resetWorkout() {
      this.totalRest = 0
      this.totalActive = 0
      this.totalImperial = 0
      this.totalMetric = 0
      this.workoutSets.forEach(set => {
        set.active = 0
        set.rest = 0
        set.metric = 0.0
        set.imperial = 0.0
        set.reps = 0
        set.saved = 'No'
        set.effort = 0
        set.distance_imperial = 0
        set.distance_metric = 0
        set.pace_imperial = 0
        set.pace_metric = 0
        set.speed_imperial = 0
        set.speed_metric = 0
        set.volume_imperial = 0
        set.volume_metric = 0
        set.time = 0
      })
      this.workout.sets = this.workoutSets
      this.workout.starttime = this.now()
      this.updatePackedWorkout(this.workout)
      clearInterval(this.interval)
      this.resetWorkRestTimer()
      this.resetRestTimer()
      this.loadRepschemes()
      this.loadUserRepmaxes()
      this.startWorkout(this.workoutid)
    },
    deleteWorkout() {
      this.deletePackedWorkout(this.workout)
      clearInterval(this.interval)
      this.resetWorkRestTimer()
      this.resetRestTimer()
      this.$router.push('/myworkouts')
    },
    loadBasic() {
      this.basic = JSON.parse(localStorage.getItem('basic'))
    }
  }
}
</script>
<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
  padding-left: 5px !important;
  padding-bottom: 5px !important;
}
.v-text-field {
  margin: 0 !important;
  padding: 0 !important;
}

.video {
  z-index: 1;
  position: absolute;
  top: 45px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.visible {
  z-index: 999;
}
</style>
